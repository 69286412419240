
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const constantRouterMap = [  
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: resolve => require(['@/views/page1/index.vue'], resolve)
  },
  {
    path: '*',
    component: resolve => require(['@/components/404.vue'], resolve)
  }
]

export default new Router({
  // mode: 'hash',
  // base: '/',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})