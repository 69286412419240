
<template>
  <div id="app" ref="app">
    <router-view></router-view>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  name: 'app',
  data () {
    return {
    }
  },
  mounted () {
    this.initEvent()
    this.setWidth()
  },
  methods: {
    initEvent () {
      document.documentElement.style.fontSize = document.documentElement.clientWidth / 20 + 'px'
      $(window).bind('resize', () => {
        document.documentElement.style.fontSize = document.documentElement.clientWidth / 20 + 'px'
        this.setWidth()
      })
    },
    setWidth () {
      this.$nextTick(() => {
        let width = document.getElementById('app').clientWidth
        let height = document.getElementById('app').clientHeight
        this.$store.commit('set_pageWidth', width)  
        // this.$store.commit('set_pageHeight', height < 900 ? 900 : height)  
        this.$forceUpdate()
      })
    }
  },
  beforeDestroy () {
    $(window).unbind('resize');
  },
}
</script>
<style scoped lang="stylus">
.app
  background-color #000
</style>
