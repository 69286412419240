
// en.js
export default {
  home: {
    lang: '中',
    scrollBottom: 'Scroll down for more',
    title1: 'We have a ',
    title2: 'professional ',
    title3: ' and technical team to provide you with ',
    title4: 'high quality services',
    title5: 'Tailor-made solutions for your problems',
    title: 'Wuhan Chenxing Visual Technology Co., Ltd',
    copyright: 'Copyright ',
  },
  menu: {
    home: 'Home',
    product: 'Product',
    services: 'Services',
    contact: 'Contact',
  },
  product: {
    title1: 'Management System',
    text1: 'With the development of cloud computing and mobile communication technologies, business systems are a core component of modern offices and play an extremely important role in daily workflow, efficiency and enterprise management.',
    title2: "Application Scenario",
    titleTip2: "[ Support multi-terminal adaptation ]",
    t1: "Business Process Management",
    t2: "Materials Managemen",
    t3: "Sales Management",
    t4: "Task Management",
    t5: "Energy Management",
    t6: "Human Resource Management",
    title2_1: "Digital Construction",
    text2_1: "Based on the background of rapid development of information technology and strong support of national policy, it has been a general trend to integrate technologies such as digital twin, data visualization and intelligent control into people's work and life. We have a lot of experience in visualization screen construction projects, and will do our best to create a visualization data screen that meets your needs.",
    title2_2: "Application Scenario",
    t2_1: "Internal Reporting",
    t2_2: "Major Events or Exhibitions",
    t2_3: "Leadership Cockpit",
    t2_4: "Digital Twin",
    title3_1: "App/website building",
    text3_1: "We have repeatedly polished the requirements and strive to realize an easy-to-use, efficient and convenient, more secure application, and provide a one-stop solution to help you create a professional, modern and user-friendly website.",
    title3_2: "Application Scenario",
    titleTip3: "[ Support multi-terminal adaptation ]",
    t3_1: "Applet",
    t3_2: "Application Software",
    t3_3: "Official Website",
    t3_4: "Mall website construction",
    t3_5: "Tool website construction",
  },
  server: {
    t1: 'Our team has served clients before',
    t2: 'Customer Business Coverage Regions and Countries',
    t3: 'Industries Served',
    t4: 'success cases',
  },
  contact: {
    contact: 'Contact Us',
    tel: 'Phone:',
    email: 'Email:',
    qq: 'QQ：',
    taobao: 'Taobao:',
    case: 'Case',
    vx: '(WeChat number)',
    copy: 'Replicating Success',
    back: 'Go back to the homepage',
    contact1: 'Please contact customer service at 18064034662',
  }
}