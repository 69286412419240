import { loadData } from '@/assets/js/cache.js'

const state = {
  user: {
    userName: null,
    userId: null,
    nickName: 'admin',
  },
  pageWidth: 200,
  currentIdx: 0,
  language: loadData().save_language || 'CN',
  storage: loadData()
}

export default state
