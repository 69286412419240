import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'

import '@/assets/stylus/index.styl'

import Clipboard from 'clipboard'
Vue.prototype.Clipboard = Clipboard//复制代码

import cn from './assets/js/cn.js'
import en from './assets/js/en.js'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'CN',
  messages: {
    "CN": cn,
    "EN": en
  }
})

Vue.config.productionTip = false

import {mapGetters, mapActions} from 'vuex'
Vue.mixin({
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['language', 'storage', 'pageWidth'])
  },
  methods:{
    getData () {},
    ...mapActions(['saveStorage'])
  },
  watch: {
    $route () {
      this.getData()
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')