
// cn.js
export default {
  home: {
    lang: 'EN',
    scrollBottom: '向下滚动查看更多',
    title1: '我们拥有',
    title2: '专业的',
    title3: '技术团队，竭力为您提供',
    title4: '优质服务',
    title5: '为您面临的问题量身定制适合的解决方案',
    title: '武汉晨兴可视科技有限公司',
    copyright: '版权所有 ',
  },
  menu: {
    home: '首页',
    product: '产品',
    services: '服务',
    contact: '联系我们',
  },
  product: {
    title1: '管理系统类',
    text1: '随着云计算和移动通讯技术的发展，业务系统是现代化办公的核心组成部分，对于日常工作流程、提升效率和企业管理有着极其重要的作用。',
    title2: "应用场景",
    titleTip2: "[ 支持多终端适配 ]",
    t1: "业务流程管理",
    t2: "物资管理",
    t3: "销售管理",
    t4: "任务管理",
    t5: "能耗管理",
    t6: "人力资源管理",
    title2_1: "数字化建设",
    text2_1: "基于信息技术飞速发展，国家政策大力扶持的背景，数字孪生、数据可视、智慧管控等技术融入人们的工作和生活已经是大势所趋。我们有着大量可视化大屏建设项目经验，将竭尽全力为您打造符合您需求的可视化数据大屏。",
    title2_2: "应用场景",
    t2_1: "内部汇报",
    t2_2: "大型活动或展览",
    t2_3: "领导驾驶舱",
    t2_4: "数字孪生",
    title3_1: "应用/网站建设",
    text3_1: "对需求反复打磨，力求实现一个简单易用、高效便捷、更加安全的应用，并且提供一站式的解决方案，帮助您打造一个专业、现代化、用户友好的网站。",
    title3_2: "应用场景",
    titleTip3: "[ 支持多终端适配 ]",
    t3_1: "小程序",
    t3_2: "应用类软件",
    t3_3: "官网建设",
    t3_4: "商城网站搭建",
    t3_5: "工具类网站建设",
  },
  server: {
    t1: '我们团队曾经服务过的客户',
    t2: '客户业务覆盖地区及国家',
    t3: '服务过的行业',
    t4: '成功案例',
  },
  contact: {
    contact: '联系我们',
    tel: '电话：',
    email: '邮箱：',
    qq: 'QQ：',
    taobao: '淘宝：',
    case: '案例',
    vx: '（微信同号）',
    copy: '复制成功',
    back: '点击返回首页',
    contact1: '请联系客服：18064034662',
  }
}