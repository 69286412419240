import * as types from './mutation-types'

const mutations = {
  set_user (state, obj) {
    state.user = obj
  },
  set_currentIdx (state, obj) {
    state.currentIdx = obj
  },
  set_pageWidth (state, obj) {
    state.pageWidth = obj
  },
  set_language (state, obj) {
    state.language = obj
  },
  set_storage (state, obj) {
    state.storage = obj
  },
}

export default mutations
